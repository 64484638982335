import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import Link from 'core/components/Link';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

function Socials({ theme, isFooter, onClick, isMonochrome }, { socials }) {
  const { colors: { primary } } = theme;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .socials
          display flex
          align-items center
          justify-content ${isFooter ? 'space-between' : 'start'}

        .socialItem
          display flex
          align-items center
          justify-content center
          width 25px
          height 25px
          border-radius 15px
          border none
          background transparent

          & + &
            margin 0 0 0 20px

          &.Vkontakte
            & :global([id*="$"])
              fill ${isMonochrome ? primary : '#4A6E9B'}

        .socialIcon
          width 20px
          height 20px
      `}</style>
    </scope>
  );

  return (
    <div className={scoped.wrapClassNames('socials')}>
      {socials.map(item => {
        const { icon: Icon } = theme.socials
          .find(({ name }) => name === item.name) || {};

        return (
          <Link
            key={item.name}
            to={item.link}
            type='blank'
            className={scoped.wrapClassNames(`socialItem ${item.name}`)}
            aria-label={item.name}
            {...onClick && { onClick: onClick.bind(null, item.shortName) }}
          >
            {Icon && <Icon className={scoped.wrapClassNames('socialIcon')} />}
          </Link>
        );
      })}
      <scoped.styles />
    </div>
  );
}

Socials.propTypes = {
  theme: PropTypes.object,
  isFooter: PropTypes.bool,
  onClick: PropTypes.func,
  isMonochrome: PropTypes.bool,
};

Socials.contextTypes = {
  socials: PropTypes.array,
};

export default withTheme(Socials);
