import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';
import { get } from 'core/libs/lodash';

import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import withMenu from 'core/components/withMenu';
import { withBreakpoint } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { menuPropTypes } from 'core/utils/prop-types/relationships';

import Socials from 'site/components/Socials';
import { SideIndent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function BaseFooter({ theme, menu, isMobile }) {
  const {
    colors: {
      primary,
      active1,
    },
    fonts: {
      display: fontDisplay,
    },
  } = theme;

  const indent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const menuItems = get(menu, 'data.attributes.menu_items', []);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .footer
          display flex
          align-items center
          justify-content space-between
          padding 30px 0
          border-top 3px solid ${primary}
          font 500 12px/19px ${fontDisplay}

          :global(.mobile) &
            flex-direction column
            text-align center
            padding 17px 0

        .footerCopy
          flex-basis 0
          color ${primary}

        .footerMenu
          display flex
          list-style none
          padding 0
          margin 0

          :global(.mobile) &
            flex-direction column
            margin 20px 0 10px 0

        .footerMenuItem
          flex-basis auto

          :global(.desktop) &
            padding-left 20px

          :global(.mobile) &
            margin 0 0 10px 0

        .footerLink
          text-decoration none
          color ${primary}
          &:visited
          &:active
            color ${primary}

          &:hover
            color ${active1}
      `}</style>
    </scope>
  );

  return (
    <SideIndent
      tag='footer'
      left={indent}
      right={indent}
    >
      <div className={scoped.wrapClassNames('footer')}>
        <div className={scoped.wrapClassNames('footerCopy')}>
          &copy;&nbsp;&laquo;группа&nbsp;компаний&nbsp;ITMS&raquo;
          <br />
          Все&nbsp;права&nbsp;защищены.
        </div>
        <ul className={scoped.wrapClassNames('footerMenu')}>
          {menuItems.map((item, i) => (
            <li key={i} className={scoped.wrapClassNames('footerMenuItem')}>
              <Link
                className={scoped.wrapClassNames('footerLink')}
                to={item.link}
              >
                {item.content}
              </Link>
            </li>
          ))}
        </ul>
        <Socials isFooter />
        <scoped.styles />
      </div>
    </SideIndent>
  );
}

BaseFooter.propTypes = {
  theme: PropTypes.object,
  /** Главное меню */
  menu: menuPropTypes(),
  isMobile: PropTypes.bool,
};

export default compose(
  withMenu('footer-menu'),
  withTheme,
  withBreakpoint,
)(BaseFooter);
