import Layout, { Header, Content, Footer } from 'core/components/Layout';

import { StickyLine } from 'site/components/Ad/AdByBreakpoint';

import Shapka from '../Shapka';
import SiteFooter from '../Footer';

export default function SiteLayout({ children }) {
  return (
    <Layout>
      <Header>
        <Shapka />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer>
        <SiteFooter />
      </Footer>
      <StickyLine />
    </Layout>
  );
}
